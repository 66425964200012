import { render, staticRenderFns } from "./PrepFooter.vue?vue&type=template&id=b80cf278&"
import script from "./PrepFooter.vue?vue&type=script&lang=js&"
export * from "./PrepFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
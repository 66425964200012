//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { minusIcon, plusIcon } from '~/static/icons';

export default {
    name: 'CommonFAQSection',
    props: {
        faqs: {
            type: Array,
            default: () => []
        }
    },
    data: () => {
        return {
            selectedFaqId: '',
            plusIcon,
            minusIcon
        };
    }
};

import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=03fd3dbe&scoped=true&"
import script from "./Home.vue?vue&type=script&lang=js&"
export * from "./Home.vue?vue&type=script&lang=js&"
import style0 from "./Home.vue?vue&type=style&index=0&id=03fd3dbe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03fd3dbe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DownloadAppNudgeBanner: require('/app/components/DownloadApp/DownloadAppNudgeBanner.vue').default,AuthModal: require('/app/components/Auth/AuthModal.vue').default})

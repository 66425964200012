//
//
//
//
//
//

import { getFullUrl } from '~/services/utilityFunctions';

export default {
    name: 'IndexPage',
    head() {
        return {
            link: [{ rel: 'canonical', href: getFullUrl(this.$route.path) }]
        };
    },
    created() {
        this.$nuxt.setLayout('default');
    }
};

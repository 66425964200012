export default {
    aboutUs: [
        {
            userName: 'Disha Jain',
            description: `I had a wonderful experience with Yocket GRE prep. The live classes were very helpful. Teachers are good and cover everything enough to keep you confident during your main test.`,
            rating: 5
        },
        {
            userName: 'Preethi Yennemadi',
            description: `I enrolled in Yocket Prep for my GRE preparation and couldn't be more satisfied with the experience. The classes were conveniently scheduled at 8 PM, which perfectly fit my schedule as a working professional. Both of my instructors were exceptional. My Verbal Trainer, Mamta Sharma Ma'am, and Quant Trainer, Harish Kumar Sir, were extremely knowledgeable and went out of their way to help with any doubts. We could easily reach out to them whenever we needed assistance, and Harish Sir, in particular, was always available to provide extra support for any issues we faced. Their teaching methods made even the most challenging concepts easy to understand, and the course content was thorough, covering everything needed to excel. Thanks to their guidance, I scored very well on the GRE. I highly recommend Yocket Prep to anyone seeking a top-notch platform for GRE preparation!`,
            rating: 5
        },
        {
            userName: 'Archita Das',
            description: `The live classes truly helped me prepare for the GRE by demonstrating how we could improve the required skills and greatly improved my score. The study materials, resources and mock tests gave me a very good idea of what kind of questions I can expect to be on the test.`,
            rating: 5
        },
        {
            userName: 'Priyanshu Chatterjee',
            description: `The best place to start your grad preparations and have counseling. I was a yocket prep student. The video lectures, questions, and mock tests which are provided on their dashboard are enough and cover everything that one needs for their GRE preparation!`,
            rating: 5
        }
    ],
    home: [
        {
            userName: 'Disha Jain',
            description: `I had a wonderful experience with Yocket GRE prep. The live classes were very helpful. Teachers are good and cover everything enough to keep you confident during your main test.`,
            rating: 5
        },
        {
            userName: 'Preethi Yennemadi',
            description: `I enrolled in Yocket Prep for my GRE preparation and couldn't be more satisfied with the experience. The classes were conveniently scheduled at 8 PM, which perfectly fit my schedule as a working professional. Both of my instructors were exceptional. My Verbal Trainer, Mamta Sharma Ma'am, and Quant Trainer, Harish Kumar Sir, were extremely knowledgeable and went out of their way to help with any doubts. We could easily reach out to them whenever we needed assistance, and Harish Sir, in particular, was always available to provide extra support for any issues we faced. Their teaching methods made even the most challenging concepts easy to understand, and the course content was thorough, covering everything needed to excel. Thanks to their guidance, I scored very well on the GRE. I highly recommend Yocket Prep to anyone seeking a top-notch platform for GRE preparation!`,
            rating: 5
        },
        {
            userName: 'Sambhavi Bhure',
            description: `The Yocket Prep dashboard covered all the topics that are tested on GRE. There are a lot of practice sets for both quant and verbal sections and covers full length tests as well. The dashboard material helped me a lot to get a good score.
            Also, I would like to specially mention that the quant faculty helped me a lot; provided me with extra practice material, guided me whenever I had doubts and even explained to me the dos and don'ts a day before my exam.
            All in all, I thank yocket prep for being a solid support in my GRE journey.`,
            rating: 5
        },
        {
            userName: 'Archita Das',
            description: `The live classes truly helped me prepare for the GRE by demonstrating how we could improve the required skills and greatly improved my score. The study materials, resources and mock tests gave me a very good idea of what kind of questions I can expect to be on the test.`,
            rating: 5
        }
    ],
    onDemand: [
        {
            userName: 'Preethi Yennemadi',
            description: `I enrolled in Yocket Prep for my GRE preparation and couldn't be more satisfied with the experience. The classes were conveniently scheduled at 8 PM, which perfectly fit my schedule as a working professional. Both of my instructors were exceptional. My Verbal Trainer, Mamta Sharma Ma'am, and Quant Trainer, Harish Kumar Sir, were extremely knowledgeable and went out of their way to help with any doubts. We could easily reach out to them whenever we needed assistance, and Harish Sir, in particular, was always available to provide extra support for any issues we faced. Their teaching methods made even the most challenging concepts easy to understand, and the course content was thorough, covering everything needed to excel. Thanks to their guidance, I scored very well on the GRE. I highly recommend Yocket Prep to anyone seeking a top-notch platform for GRE preparation!`,
            rating: 5
        },
        {
            userName: 'Sambhavi Singh',
            description: `I am glad that I took the GRE prep course from Yocket trainers and scored 314.\n\nQuants: The concepts, examples, and practice tests given by Harish Sir cover everything one needs to know to clear the Quants section with flying colors. He is a highly proficient trainer.\n\nVerbal: This section needs immense practice for those with average English vocab. However, the English faculty gave simple tricks and tips to solve almost any type of verbal question.\n\nThere is absolutely no need to look for GRE material outside as the topics are covered in detail. Multiple practice questions are given by the trainers during the classes in addition to the practice homework. These classes followed by multiple mock tests are sufficient.`,
            rating: 5
        },
        {
            userName: 'Priyanshu Chatterjee',
            description: `The best place to start your grad preparations and have counseling. I was a yocket prep student. The video lectures, questions, and mock tests which are provided on their dashboard are enough and cover everything that one needs for their GRE preparation!`,
            rating: 5
        }
    ],
    liveClass: [
        {
            userName: 'Preethi Yennemadi',
            description: `I enrolled in Yocket Prep for my GRE preparation and couldn't be more satisfied with the experience. The classes were conveniently scheduled at 8 PM, which perfectly fit my schedule as a working professional. Both of my instructors were exceptional. My Verbal Trainer, Mamta Sharma Ma'am, and Quant Trainer, Harish Kumar Sir, were extremely knowledgeable and went out of their way to help with any doubts. We could easily reach out to them whenever we needed assistance, and Harish Sir, in particular, was always available to provide extra support for any issues we faced. Their teaching methods made even the most challenging concepts easy to understand, and the course content was thorough, covering everything needed to excel. Thanks to their guidance, I scored very well on the GRE. I highly recommend Yocket Prep to anyone seeking a top-notch platform for GRE preparation!`,
            rating: 5
        },
        {
            userName: 'Sambhavi Bhure',
            description: `The Yocket Prep dashboard covered all the topics that are tested on GRE. There are a lot of practice sets for both quant and verbal sections and covers full length tests as well. The dashboard material helped me a lot to get a good score.
            Also, I would like to specially mention that the quant faculty helped me a lot; provided me with extra practice material, guided me whenever I had doubts and even explained to me the dos and don'ts a day before my exam.
            All in all, I thank yocket prep for being a solid support in my GRE journey.`,
            rating: 5
        },
        {
            userName: 'Avani Jain',
            description: `My experience with Yocket professors during my GRE prep was eye-opening. They had a talent for breaking down tough concepts, making learning both fun and effective. I'm really thankful for all the invaluable knowledge I gained from their teaching.`,
            rating: 5
        },
        {
            userName: 'Sambhavi Singh',
            description: `I am glad that I took the GRE prep course from Yocket trainers and scored 314.\n\nQuants: The concepts, examples, and practice tests given by Harish Sir cover everything one needs to know to clear the Quants section with flying colors. He is a highly proficient trainer.\n\nVerbal: This section needs immense practice for those with average English vocab. However, the English faculty gave simple tricks and tips to solve almost any type of verbal question.\n\nThere is absolutely no need to look for GRE material outside as the topics are covered in detail. Multiple practice questions are given by the trainers during the classes in addition to the practice homework. These classes followed by multiple mock tests are sufficient.`,
            rating: 5
        }
    ],
    pricing: [
        {
            userName: 'Sambhavi Bhure',
            description: `The Yocket Prep dashboard covered all the topics that are tested on GRE. There are a lot of practice sets for both quant and verbal sections and covers full length tests as well. The dashboard material helped me a lot to get a good score.
        Also, I would like to specially mention that the quant faculty helped me a lot; provided me with extra practice material, guided me whenever I had doubts and even explained to me the dos and don'ts a day before my exam.
        All in all, I thank yocket prep for being a solid support in my GRE journey.`,
            rating: 5
        },
        {
            userName: 'Avani Jain',
            description: `My experience with Yocket professors during my GRE prep was eye-opening. They had a talent for breaking down tough concepts, making learning both fun and effective. I'm really thankful for all the invaluable knowledge I gained from their teaching.`,
            rating: 5
        },
        {
            userName: 'Sambhavi Singh',
            description: `I am glad that I took the GRE prep course from Yocket trainers and scored 314.\n\nQuants: The concepts, examples, and practice tests given by Harish Sir cover everything one needs to know to clear the Quants section with flying colors. He is a highly proficient trainer.\n\nVerbal: This section needs immense practice for those with average English vocab. However, the English faculty gave simple tricks and tips to solve almost any type of verbal question.\n\nThere is absolutely no need to look for GRE material outside as the topics are covered in detail. Multiple practice questions are given by the trainers during the classes in addition to the practice homework. These classes followed by multiple mock tests are sufficient.`,
            rating: 5
        },
        {
            userName: 'Priyanshu Chatterjee',
            description: `The best place to start your grad preparations and have counseling. I was a yocket prep student. The video lectures, questions, and mock tests which are provided on their dashboard are enough and cover everything that one needs for their GRE preparation!`,
            rating: 5
        }
    ]
};

//
//
//
//
//

export default {
    name: 'GoogleCaptcha',
    provide() {
        return {
            reCaptchaValidate(action) {
                return new Promise((resolve) => {
                    grecaptcha.ready(function () {
                        grecaptcha
                            .execute(process.env.RECAPTCHA_SITE_KEY, { action })
                            .then((token) => {
                                resolve(token);
                            });
                    });
                });
            }
        };
    },
    data() {
        return {
            siteKey: process.env.RECAPTCHA_SITE_KEY
        };
    },
    mounted() {
        const shouldUseRecaptcha = process.env.USE_RECAPTCHA === 'true';
        if (shouldUseRecaptcha) {
            const recaptchaScript = document.createElement('script');
            recaptchaScript.setAttribute(
                'src',
                `https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_SITE_KEY}`
            );
            document.head.appendChild(recaptchaScript);
        }
    }
};

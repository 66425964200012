//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import analyticEvents from '~/services/analyticEvents';
import {
    SOCIALS,
    COMPANY_LINKS,
    GUIDE_LINKS,
    BLOG_LINKS,
    CONTACT_LINKS
} from '~/static/constants.js';
import { emailIcon, phoneIconBlack } from '~/static/icons';
const CDN = `${process.env.CDN_ENDPOINT}/testprep/static`;

export default {
    name: 'HomeFooter',
    data() {
        return {
            CDN,
            SOCIALS,
            hoveredIcon: null,
            lists: [
                {
                    heading: 'COMPANY',
                    items: [
                        {
                            title: 'About us',
                            link: COMPANY_LINKS.ABOUT_US
                        },
                        {
                            title: 'Privacy Policy',
                            link: COMPANY_LINKS.PRIVACY_POLICY
                        },
                        {
                            title: 'Help Center',
                            link: COMPANY_LINKS.HELP_CENTER
                        },
                        {
                            title: 'Careers',
                            link: COMPANY_LINKS.CAREERS
                        }
                    ]
                },
                {
                    heading: 'GUIDES',
                    items: [
                        {
                            title: 'GMAT Guide',
                            link: GUIDE_LINKS.GMAT
                        },
                        {
                            title: 'GRE Guide',
                            link: GUIDE_LINKS.GRE
                        }
                    ]
                },
                {
                    heading: 'BLOGS',
                    items: [
                        {
                            title: 'GMAT Blogs',
                            link: BLOG_LINKS.GMAT
                        },
                        {
                            title: 'GRE Blogs',
                            link: BLOG_LINKS.GRE
                        }
                    ]
                },
                {
                    heading: 'GET IN TOUCH',
                    items: [
                        {
                            title: '18002706088',
                            link: CONTACT_LINKS.PHONE,
                            leadingIcon: phoneIconBlack
                        },
                        {
                            title: 'support@yocket.in',
                            link: CONTACT_LINKS.EMAIL,
                            leadingIcon: emailIcon
                        }
                    ]
                }
            ]
        };
    },
    computed: {
        socialHandles() {
            const arr = [];

            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(this.SOCIALS)) {
                arr.push(value);
            }
            return arr;
        }
    },
    methods: {
        handleTrackEvent(title) {
            const trackingEvent = {
                event: analyticEvents.footer_element_clicked,
                data: {
                    element_name: title
                }
            };
            this.$store.dispatch('analytics/trackEvent', trackingEvent);
        }
    }
};

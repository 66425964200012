//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { chevronRightWhite, sparkleWhite } from '@/static/icons/index.js';
import { ROUTES } from '~/constants/pageRoutes';
import analyticEvents from '~/services/analyticEvents';
import { COMING_SOON_SOURCE_MODULES } from '~/static/constants';
export default {
    name: 'ComingSoonTag',
    props: {
        icon: {
            default: sparkleWhite
        },
        title: {
            default: 'GMAT Prep Coming Soon!',
            type: String,
            require: true
        },
        url: {
            default: ROUTES.BOOK_DEMO,
            type: String,
            require: true
        },
        source: {
            default: COMING_SOON_SOURCE_MODULES.HOME_PAGE,
            type: String,
            require: true
        }
    },
    data: () => {
        return {
            chevronRightWhite
        };
    },
    methods: {
        handleTrackEvent() {
            const trackingEvent = {
                event: analyticEvents.gmat_prep_label,
                data: {
                    source: this.source
                }
            };
            this.$store.dispatch('analytics/trackEvent', trackingEvent);
        }
    }
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getProfilePic } from '~/services/utilityFunctions';
import { fullPurpleStar, halfPurpleStar } from '~/static/icons';

export default {
    name: 'TestimonialCard',
    props: {
        testimonial: {
            type: Object,
            default: () => ({
                userName: '',
                description: '',
                rating: 0
            }),
            required: true
        }
    },
    data() {
        return {
            isExpanded: false,
            maxLength: 200,
            fullPurpleStar,
            halfPurpleStar,
            getProfilePic
        };
    },
    computed: {
        shortenedDescription() {
            if (this.testimonial.description.length > this.maxLength) {
                return this.testimonial.description.substring(0, this.maxLength) + '...';
            }
            return this.testimonial.description;
        },
        showReadMore() {
            return this.testimonial.description.length > this.maxLength;
        },
        formattedDescription() {
            return this.testimonial.description.replace(/\n/g, '<br>');
        },
        formattedShortenedDescription() {
            return this.shortenedDescription.replace(/\n/g, '<br>');
        },
        description() {
            return this.isExpanded ? this.formattedDescription : this.formattedShortenedDescription;
        },
        wholeRating() {
            return Math.floor(this.testimonial.rating);
        },
        isHalfRating() {
            return this.testimonial.rating % 1 === 0.5;
        }
    },
    methods: {
        toggleExpand() {
            this.isExpanded = !this.isExpanded;
        }
    }
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fullYellowStar, halfYellowStar, laurelWreathPurple } from '~/static/icons';

export default {
    name: 'TopReviewsBadge',
    data: () => {
        return {
            laurelWreathPurple,
            fullYellowStar,
            halfYellowStar
        };
    }
};

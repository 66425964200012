//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'LandingPageSectionHeadings',
    props: {
        title: {
            type: String,
            default: 'Section Heading'
        }
    }
};
